.cluster-marker {
  color: #fff;
  background: #36C24C;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker-transparent { 
    background: rgba(54, 194, 76, 0.5);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }